import React from 'react';
import SEO from '../../structure/seo';
import Layout from '../../structure/layout';
import Index from '../../structure/pages/neighborhood/lakes/index';


const TheLakes = () => {
    return (
        <Layout shadowNav={false}>
            <Index />
            <SEO title={'Los lagos'} />
        </Layout>
    );
}

export default TheLakes;