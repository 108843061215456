import React, { useState } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";
import { Container, Form, Col, InputGroup, Row, Button } from 'react-bootstrap';

const ContainerTitle = styled.section`
    background-color: #1E2E48;
    padding:2.5% 2.5%;
    margin-top: 7rem;
`
const P = styled.p`
    text-align: justify;
    color:#fff;
    padding-top: 2%;

`
const H2 = styled.h2`
    text-align: center;
    color:#fff;
    font-size: 3rem;
`
const H3 = styled.h3`
    text-align: center;
    color:#C1292F;
    font-size: 1.2rem;
`

const Index = () => {
    return (
        <>
            <ContainerTitle>
                <H2>Los lagos</H2>
            </ContainerTitle>
            <Container>
                <nav aria-label="breadcrumb" className="mt-5">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a style={{ color: "#000", textDecoration: "none" }} href="/">INICIO</a></li>
                        <li className="breadcrumb-item"><a style={{ color: "#000", textDecoration: "none" }} href="/">BARRIOS</a></li>
                        <li className="breadcrumb-item active" aria-current="page">LOS LAGOS</li>
                    </ol>
                </nav>
                <Row className="justify-content-center">
                    <Col lg={8} className="text-center">
                        <StaticImage src="../../../../images/logo-03.png" />
                        <H2 style={{ color: "#1E2E48" }} className="mt-5">Sobre Costa del Arroyo San Juan.</H2>
                        <H3>NAVEGABLE HASTA EL RIO PARANA EN LAS ALTURAS NORMALES DEL RIO</H3>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-5">
                    <Col lg={4} className="text-justify">
                        <P className="text-dark"><strong>Característica:</strong> Barrio abierto, con terrenos residenciales de m2 a m2. En orientación Este-Oeste cuenta con el espejo de aguas del Arroyo San Juan y en su interior paralelo al Arroyo se construyeron Lagos para cría de peces y actividades náuticas menores.</P>
                    </Col>
                    <Col lg={4} className="text-justify">
                        <P className="text-dark"><strong>Ubicación:</strong> Por camino principal de ingreso,  se ubica a continuación del Barrio “El Portal“</P>
                        <P className="text-dark"><strong>Superficie total común:</strong> 216.100 m2 ( Doscientos trece mil cien metros cuadrados) (216.100 m2)</P>
                    </Col>
                    <Col lg={4} className="text-justify">
                        <P className="text-dark"><strong>Bienes de Infraestructura</strong> Calle colectora con calles secundarias de buena transitabilidad, Red de alumbrado público, espacios verdes, Arroyo San Juan y lagos en el interior del Barrio</P>
                    </Col>
                </Row>
                <StaticImage src="../../../../images/LosLagos.png" className="mt-5" />
                <StaticImage src="../../../../images/LosLagosTable.png" className="mt-5" />
            </Container>
        </>
    );
}

export default Index;